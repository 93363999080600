import axios from 'axios';
import { server_url } from '../constants/index';
import {logout} from '../modules/Auth/reducer/authSlice';


const axiosInstance = axios.create({
  baseURL: `${server_url}`,
  // timeout: 8000,
  headers: {
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setupAxiosInterceptors = (store) => {
  axiosInstance.interceptors.response.use((response) => response,
    (error) => {

      console.log("this error",error);
      
      if (error.response && error.response.status === 401) {
        store.dispatch(logout({ redirect: true }));
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
