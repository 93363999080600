import React from "react";
import { Flex, useColorModeValue, useColorMode } from "@chakra-ui/react";
import routes from "../../routes";
import NavLink from "./NavLink";

export default function Navbar() {
  let navbarBg = useColorModeValue("#2376dc", "navy.800");
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      bg={navbarBg}
      minH={["50px", "60px", "70px", "10px"]} 
      alignItems="center"
      pl={5}
      pr={5}
    >
      {routes.map((link) => (
        <NavLink>{link}</NavLink>
      ))}
    </Flex>
  );
}
