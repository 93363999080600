import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import store from './app/store'
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import "./index.css"
import theme from './them/theme'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <BrowserRouter>
      <ChakraProvider theme={theme} resetCss={false}  >
         <Provider store={store}>
            <App />
         </Provider>
      </ChakraProvider>

   </BrowserRouter>
);
