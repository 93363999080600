import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  MdReceipt
} from "./components/Icons/Icons";

import Category from './/modules/Category'
import Item from './/modules/Item'

const route = [
  {
    name: "Master Entry",
    path: "/dashboard",
    icon: <StatsIcon color="inherit" />,
    subRoutes: [
      {
        name: "Item Register",
        path: "/category",
        icon: <StatsIcon color="inherit" />,
        component: <Item/>,
      },
   
    ],
  },
  {
    name: "Customer",
    path: "/customer",
    icon: <StatsIcon color="inherit" />,
    component: <Category/>,
  },
  {
    name: "Supplier",
    path: "/supplier",
    icon: <StatsIcon color="inherit" />,
    component: <Category/>,
  },
  // {
  //   name: "Sales",
  //   path: "/purchases",
  //   icon: <StatsIcon color="inherit" />,
  //   component: "",
  // },
  // {
  //   name: "Reports",
  //   path: "/purchases",
  //   icon: <StatsIcon color="inherit" />,
  //   component: "",
  // },
];

export default route;
