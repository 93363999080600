import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Tfoot,
  HStack,
  Box,
  Flex,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { getAllCategoryApi } from '../../../modules/Category/services';
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { deleteCategoryApi } from '../../../modules/Category/services'

export default function TableData({ handleCategoryId, handleOpenModal }) {
  const [currentPage, setCurrentPage] = useState(1);

  const toast = useToast();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, isError, message, data } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getAllCategoryApi(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Category Fetch",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right", 

      });
    }

    if (isError) {
      toast({
        title: "Category",
        description: message || "Something went wrong ",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right", 

      });
    }

    return () => {
      toast.closeAll()
    }
  }, [isSuccess, isError, message, toast]);

  const handleEdit = (id) => {
    handleOpenModal()
    console.log("Edit row with ID:", id);
    handleCategoryId(id)
  };

  const handleDelete = (id) => {
    dispatch(deleteCategoryApi(id));

  };

  const handleNextPage = () => {
    if (data?.data?.currentPage < data?.data?.totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };


  return (
    <TableContainer>
      <Table size="sm" border="2px solid black" >
        <Thead>
          <Tr bg="black" height="40px">
            <Th color="white" borderRight="1px solid white">
              Name
            </Th>
            <Th color="white" borderRight="1px solid white">
              Main Group
            </Th>
            <Th color="white" borderRight="1px solid white">
              Index Number
            </Th>
            <Th color="white" borderRight="1px solid white">
              Group Number
            </Th>
            <Th color="white" borderRight="1px solid white">
              Action
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {data?.data.categories && data.data.categories.length > 0 ? (
            data.data.categories.map((category) => (
              <Tr key={category.id} height="40px">
                <Td p={2}>{category.categoryName}</Td>
                <Td p={2}>{category.mainGroup}</Td>
                <Td p={2}>{category.indexNo}</Td>
                <Td p={2}>{category.groupNo}</Td>
                <Td p={2}>
                  <IconButton
                    borderRadius={0}
                    icon={<EditIcon />}
                    size="sm"
                    mr={2}
                    onClick={() => handleEdit(category._id)}
                  />
                  <IconButton
                    borderRadius={0}
                    icon={<DeleteIcon />}
                    size="sm"
                    onClick={() => handleDelete(category._id)}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr height="40px">
              <Td colSpan={5} textAlign="center">
                No categories available.
              </Td>
            </Tr>
          )}
        </Tbody>

        <Tfoot>
          <Tr bg="black" height="40px">
            <Td colSpan={5}>
              <Flex justify="space-between" align="center" color="white">
                <Box>Total items: {data?.data?.totalItems || 0}</Box>
                <HStack mr={5}>
                  <IconButton
                    borderRadius={0}
                    icon={<ChevronLeftIcon />}
                    bg="none"
                    color="white"
                    fontSize={30}
                    size="sm"
                    onClick={handlePreviousPage}
                    isDisabled={currentPage === 1}

                  />
                  <Box color="white">Page {data?.data?.currentPage} of {data?.data?.totalPages} </Box>
                  <IconButton
                    borderRadius={0}
                    bg="none"
                    color="white"
                    fontSize={30}
                    icon={<ChevronRightIcon />}
                    size="sm"
                    onClick={handleNextPage}
                    isDisabled={data?.data?.currentPage >= data?.data?.totalPages}
                  />
                </HStack>
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}
