import { background, extendTheme } from "@chakra-ui/react"
import { mode } from '@chakra-ui/theme-tools'
import { height, padding, width } from "@mui/system"
const breakpoints = {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
}


const theme = extendTheme({
    // initialColorMode : "light",
    colors: {
        gray: {
            700: "#1f2733",
        },
        navy: {
            50: "#d0dcfb",
            100: "#aac0fe",
            200: "#a3b9f8",
            300: "#728fea",
            400: "#3652ba",
            500: "#1b3bbb",
            600: "#24388a",
            600: "#24388a",
            700: "#1b254b",
            800: "#111c44",
            900: "#0b1437",
        },
    },

    styles: {
        global: (props) => ({
            body: {
                overflowX: "hidden",
                bg: mode("gray.50", "#1B254B")(props),
                fontFamily: "Helvetica, sans-serif",
            },
            html: {
                fontFamily: "Helvetica, sans-serif",
            },
        })
    },
    components: {
        MainPanel: {
            baseStyle: {
                transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
                transitionDuration: '.2s, .2s, .35s',
                transitionProperty: 'top, bottom, width',
                transitionTimingFunction: 'linear, linear, ease',
                background: "#2d2d2d", // Dark grey background (close to black)
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                height: "100vh",


            }
        },
        PanelContent: {
            baseStyle: {
                ms: "auto",
                me: "auto",
                // ps: "15px",
                // pe: "15px",
                // bg: "red",
                mt: "10px",
                // bg:"yellow"

            },
        },
        PanelContainer: {
            baseStyle: {
                // p: "15px 15px",
                minHeight: "calc(100vh - 123px)",
                
            },
        },
        Button: {
            variants: {
                primary: {
                    fontSize: "13px",
                    // bg: "blue.400",
                    bg: "#2376dc",
                    color: "#fff",
                    borderRadius : "0px",
                    
                    
                    // _hover: { bg: "blue.300" },
                    // _focus: { bg: "blue.300" },
                    // _active: { bg: "blue.300" },
                },
                navy: {
                    fontSize: "10px",
                    bg: "navy.900",
                    color: "#fff",
                    _hover: { bg: "navy.900" },
                    _focus: { bg: "navy.900" },
                    _active: { bg: "navy.900" },
                },
                "no-effects": {
                    _hover: "none",
                    _active: "none",
                    _focus: "none",
                },
                danger: () => ({
                    color: "white",
                    bg: "red.500",
                    fontSize: "10px",
                    _hover: "red.400",
                    _focus: "red.400",
                    _active: "red.400",
                }),
                outlined: (props) => ({
                    color: mode("blue.400", "white")(props),
                    bg: "transparent",
                    fontSize: "10px",
                    border: "1px solid",
                    borderColor: { bg: mode("blue.400", "white")(props) },
                    _hover: { bg: mode("blue.50", "transparent")(props) },
                    _focus: { bg: mode("blue.50", "transparent")(props) },
                    _active: { bg: mode("blue.50", "transparent")(props) },
                }),
                dark: (props) => ({
                    color: "white",
                    bg: mode("gray.700", "blue.500")(props),
                    fontSize: "10px",
                    _hover: { bg: mode("gray.700", "blue.500")(props) },
                    _focus: { bg: mode("gray.700", "blue.600")(props) },
                    _active: { bg: mode("gray.700", "blue.400")(props) },
                }),
                light: (props) => ({
                    color: mode("gray.700", "gray.700")(props),
                    bg: mode("gray.100", "white")(props),
                    fontSize: "10px",
                    _hover: { bg: mode("gray.50", "white")(props) },
                    _focus: { bg: mode("gray.50", "white")(props) },
                    _active: { bg: mode("gray.50", "white")(props) },
                }),
            },
            baseStyle: {
                // fontWeight: "bold",
                // borderRadius: "8px",
                fontSize: "10px",
                // width:"100px",
                // height:"10px",
                padding:"0px"
            },
        },
        Input: {
            variants: {
              primary: {
                field: {
                  borderRadius: "2px",
                  border: "1px solid gray",
                  padding: 0,
                  px: 0,
                  py: 0,
                  lineHeight: "1.5",
                  height: "auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  minWidth: "150px",
                },
              },
              auth: (props) => ({
                field: {
                  bg: mode("white", "navy.700")(props),
                  border: "1px solid",
                  borderColor: mode("gray.200", "transparent")(props),
                  _placeholder: { color: mode("gray.300", "gray.400")(props) },
                },
              }),
              search: (props) => ({
                field: {
                  border: "none",
                  py: "11px",
                  borderRadius: "inherit",
                  _placeholder: { color: mode("gray.300", "gray.400")(props) },
                },
              }),
              
            },
            
          },
    },

    breakpoints


})

export default theme