import React, { useState } from "react";
import { Box, Flex, Button, Heading } from "@chakra-ui/react";
import TableData from "./components/TableData";
import CategoryCreateAndUpdate from "./components/CategoryCreateAndUpdate";

export default function Category() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const handleOpenModal = () => {
    setCategoryId(null);  // Reset categoryId when opening the Create modal
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Box width="100%" p={5}>
      <Flex flexDirection="row" justifyContent="space-between" pb={5}>
        <Heading size="md">Category</Heading>
        <Button variant="primary" onClick={handleOpenModal}>
          Create Category
        </Button>
      </Flex>
      <TableData
        handleOpenModal={() => setIsModalOpen(true)}
        handleCategoryId={setCategoryId}
      />
      <CategoryCreateAndUpdate
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        categoryId={categoryId}
      />
    </Box>
  );
}
