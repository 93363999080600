import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../modules/Auth/reducer/authSlice'
import categoryReducer from '../modules/Category/reducer/categorySlice'
import { setupAxiosInterceptors } from '../services/axiosInstance'; 

const store = configureStore({
    reducer : {
        login : loginReducer,
        category : categoryReducer
    }
})

setupAxiosInterceptors(store);

export default store;