import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance"; 


export const categoryCreateApi = async (data) => {
    try {
        const response = await axiosInstance.post(`/category/create`,data);
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
}

export const categorySearchApi = async(categoryName)=>{
    
    try {
        const response = await axiosInstance.get(`/category/search?name=${categoryName}`);
        console.log(response.data);
        
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
}
