import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCategoryApi,
  createCategoryApi,
  deleteCategoryApi,
  updateCategoryApi,
} from "../services";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
  },

  // Handling Get Category api

  extraReducers: (builder) => {
    builder
      .addCase(getAllCategoryApi.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getAllCategoryApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.data = action.payload;
      })
      .addCase(getAllCategoryApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });

    // Handling createCategoryApi
    builder
      .addCase(createCategoryApi.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(createCategoryApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;

        const newCategory = action.payload.data;
        state.data.data.categories = [
          newCategory,
          ...state.data.data.categories,
        ];
        state.data.data.totalItems = state.data.data.totalItems + 1
      })
      .addCase(createCategoryApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });

    // Handling deleteCategoryApi
    builder
      .addCase(deleteCategoryApi.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(deleteCategoryApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;

        const deletedCategoryId = action.payload.data._id;
        state.data.data.categories = state.data.data.categories.filter(
          (category) => category._id !== deletedCategoryId
        );
        state.data.data.totalItems = state.data.data.totalItems - 1

      })
      .addCase(deleteCategoryApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });

    // Handling updateCategoryApi
    builder
      .addCase(updateCategoryApi.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(updateCategoryApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;

        const updatedCategory = action.payload.data;
        state.data.data.categories = state.data.data.categories.map(
          (category) =>
            category._id === updatedCategory._id ? updatedCategory : category
        );
      })
      .addCase(updateCategoryApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });
  },
});

export default categorySlice.reducer;
