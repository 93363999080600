import React, { useState } from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function NavLink(props) {
  const { children } = props;
  const [isHovered, setIsHovered] = useState(false);
  let navbarBg = useColorModeValue("#2376dc", "navy.800");

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Flex
      direction="column"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        alignItems="center"
        _hover={{
          textDecoration: "none",
          bg: "red.500",
        }}
        px={2}
        py={1}
        cursor="pointer"
        height="50px"
        onClick={() => handleNavigation(children.path)} 
      >
        {/* <Box display="flex" alignItems="center" pr="5px" color="white">
          {children.icon}
        </Box> */}

        <Box display="flex" alignItems="center" color="white" pr="3px">
          {children.name}
        </Box>

        {children.subRoutes && <ChevronDownIcon color="white" />}
        {children.subRoutes && isHovered && (
          <Flex
            direction="column"
            width="200px"
            position="absolute"
            top="100%"
            left="0"
            bg={navbarBg}
            align="start"
          >
            {children.subRoutes.map((subRoute, index) => (
              <Flex
                w="full"
                key={index}
                color="white"
                p={2}
                gap={2}
                alignItems="center"
                _hover={{ bg: "red.600", color: "white" }}
                onClick={(event) => {
                  event.stopPropagation(); 
                  handleNavigation(subRoute.path); 
                }}
              >
                {/* <Box display="flex" alignItems="center" pr="5px" color="white">
                  {subRoute.icon}
                </Box> */}

                <Box w="full">{subRoute.name}</Box>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
