// export const server_url = 'https://api.dev.wine.tdrsoftware.in/api/v1';
export const server_url = 'http://localhost:5008/api/v1';

export var dataConstant = new Object();

dataConstant.status = [
    {
        'id':1,
        'name': 'Active',
        'value' : "Active"
    },
    {
        'id':2,
        'name': 'InActive',
        'value' : "InActive" 
    }
];
