import React, { useEffect, useState } from "react";
import Model from "../../../components/Model";
import {
  Box,
  Flex,
  Button,
  FormLabel,
  Input,
  Text,
  Spinner,
} from "@chakra-ui/react"; // Import Spinner
import { FormControl } from "@mui/material";
import { useForm } from "react-hook-form";
import { categoryCreateApi } from "../services";
import { useToast } from "@chakra-ui/react";

export default function CategoryCreate({ isModalOpen, handleCloseModal }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false); // Create loading state

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    try {
      const response = await categoryCreateApi(data);
      // Show success toast message
      toast({
        title: "Category created.",
        description: response.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      // Reset form after successful submission
      reset();
      handleCloseModal();
    } catch (error) {
      // Show error toast message
      toast({
        title: "Error.",
        description: error.message || "Failed to create category.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <Model
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Create Category"
        footerButton={
          <Button
            type="submit"
            variant="primary"
            size="md"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        }
      >
        <form>
          <Flex>
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
                Category Name :
              </FormLabel>
              <Input
                name="categoryName"
                {...register("categoryName", {
                  required: "Category is required",
                })}
                sx={{
                  borderRadius: "2px",
                  border: "1px solid gray",
                  padding: 0,
                  px: 0,
                  py: 0,
                  lineHeight: "1.5",
                  height: "auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  minWidth: "150px",
                }}
              />
              {errors.categoryName && (
                <Text color="red.500">{errors.categoryName.message}</Text>
              )}
            </FormControl>
          </Flex>
        </form>
      </Model>

      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0, 0, 0, 0.5)"
          zIndex="1000"
        >
          <Flex align="center" justify="center" height="100%">
            <Spinner size="lg" color="white" />
          </Flex>
        </Box>
      )}
    </>
  );
}
