import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { createCategoryApi, updateCategoryApi, categoryGetApi } from "../services";
import { useForm } from "react-hook-form";
import Model from "../../../components/Model";
import { useToast } from "@chakra-ui/react";

export default function CategoryCreateAndUpdate({
  isModalOpen,
  handleCloseModal,
  categoryId,
}) {
  const dispatch = useDispatch();
  const toast = useToast();

  const modelTitle = categoryId ? "Update Category" : "Create Category";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Fetch category 
  useEffect(() => {
    let isMounted = true;

    // Reset form data when the modal opens
    if (isModalOpen && categoryId) {
      const fetchCategory = async () => {
        try {
          const response = await categoryGetApi(categoryId);
          if (response && response.data && isMounted) {
            reset({
              categoryName: response.data.categoryName,
              mainGroup: response.data.mainGroup,
              indexNo: response.data.indexNo,
              groupNo: response.data.groupNo,
            });
            toast({
              title: "Category Fetch",
              description: response.message,
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right", 

            });
          }
        } catch (error) {
          console.error("Error fetching category details:", error);
        }
      };
      fetchCategory();
    }

    // Reset form when modal closes
    if (!isModalOpen) {
      reset({
        categoryName: "",
        mainGroup: "",
        indexNo: "",
        groupNo: "",
      });
    }

    return () => {
      isMounted = false;
      toast.closeAll()

    };
  }, [isModalOpen, categoryId, reset, toast]);

  const onSubmit = (data) => {    
    if (categoryId) {
      dispatch(updateCategoryApi({ data, id: categoryId }));
    } else {
      dispatch(createCategoryApi(data));
    }
    reset();
    handleCloseModal();
  };

  return (
    <Model
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      title={modelTitle}
      footerButton={
        <Button
          type="submit"
          variant="primary"
          size="md"
          onClick={handleSubmit(onSubmit)}
        >
          {categoryId ? "Update" : "Save"}
        </Button>
      }
    >
      <form>
        <Flex p={5} gap={5}>
          <FormControl id="categoryName" mb={4}>
            <FormLabel>Category Name</FormLabel>
            <Input
              border="1px solid"
              name="categoryName"
              {...register("categoryName", { required: "Category is required" })}
            />
            {errors.categoryName && (
              <Text color="red.500">{errors.categoryName.message}</Text>
            )}
          </FormControl>

          <FormControl id="mainGroup" mb={4}>
            <FormLabel>Main Group</FormLabel>
            <Input
              border="1px solid"
              name="mainGroup"
              {...register("mainGroup", { required: "Main Group is required" })}
            />
            {errors.mainGroup && (
              <Text color="red.500">{errors.mainGroup.message}</Text>
            )}
          </FormControl>

          <FormControl id="indexNo">
            <FormLabel>Index Number</FormLabel>
            <Input
              border="1px solid"
              name="indexNo"
              {...register("indexNo", { required: "Index Number is required" })}
            />
            {errors.indexNo && (
              <Text color="red.500">{errors.indexNo.message}</Text>
            )}
          </FormControl>

          <FormControl id="groupNo">
            <FormLabel>Group Number</FormLabel>
            <Input
              border="1px solid"
              name="groupNo"
              {...register("groupNo", { required: "Group Number is required" })}
            />
            {errors.groupNo && (
              <Text color="red.500">{errors.groupNo.message}</Text>
            )}
          </FormControl>
        </Flex>
      </form>
    </Model>
  );
}
