import React from "react";
import Model from "../../../components/Model";
import {
  Box,
  Flex,
  Heading,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormLabel,
  Input,
  Stack,
  IconButton,
  CardFooter,
} from "@chakra-ui/react";
import { FormControl } from "@mui/material";
import { AddIcon } from "@chakra-ui/icons";

export default function BrandCreate({ isModalOpen, handleCloseModal }) {
  return (
    <Model
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      title="Brand Category"
      footerButton={
        <Flex justifyContent="center" alignItems="center" w="100%">
          <Button type="submit" variant="primary" size="md">
            Save
          </Button>
        </Flex>
      }
    >
      <Flex >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            // background: "red",
          }}
        >
          <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
            Brand Name :
          </FormLabel>
          <Input
            sx={{
              borderRadius: "2px",
              border: "1px solid gray",
              padding: 0,
              px: 0,
              py: 0,
              lineHeight: "1.5",
              height: "auto",
              paddingLeft: "5px",
              paddingRight: "5px",
              minWidth: "150px",
            }}
          />
          {/* <IconButton
            aria-label="Add"
            icon={<AddIcon />}
            onClick={() => console.log("Add button clicked")}
            variant="outline"
            size="xs"
            sx={{
              borderRadius: 0,
              border: "1px solid green",
              padding: 0,
            }}
          /> */}
        </FormControl>
      </Flex>
    </Model>
  );
}
