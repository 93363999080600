import { useStyleConfig } from "@chakra-ui/react";
import React, { Component } from "react";

import {
  Box,
  Flex,
  Heading,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormLabel,
  Input,
  Stack,
  IconButton,
  CardFooter,
} from "@chakra-ui/react";
function PanelContainer(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("PanelContainer", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    // <Box __css={styles} {...rest}>
    //   {children}
    // </Box>

    <Card width="70%" p={1} bg="ffff" borderRadius={0}>
      <CardHeader m={0} p={0} bg="white">
        {/* White background for CardHeader */}
        <Flex justifyContent="space-between" alignItems="center" pl={1} pr={1}>
          <Heading size="sm" color="black">
            Item Create
          </Heading>{" "}
          {/* Black heading for contrast */}
          <Button variant="primary">Back</Button>
        </Flex>
      </CardHeader>
      <CardBody bg="#ffe6e6" pl={1} pr={1} pt={2}>

        {children}


      </CardBody>
      <CardFooter p={0} bg="white">
        <Flex
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            padding: "0px",
          }}
        >
          {/* Set width to 100% */}
          <Button variant="primary">Save</Button>
        </Flex>
      </CardFooter>
    </Card>
  );
}

export default PanelContainer;
