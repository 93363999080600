import React, { useEffect, useState } from "react";
import { Box, Button, Flex, useColorMode } from "@chakra-ui/react";
import Navbar from "../navbar/Navbar";
import MainPanel from "../MainPanel";
import { Routes, Route, Navigate } from "react-router-dom";
import PanelContent from "../PanelContent";
import PanelContainer from "../PanelContainer";
import Footer from "../Footer";
import routes from "../../routes";

export default function Layout() {
  const { colorMode } = useColorMode();


  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.subRoutes) {
        return route.subRoutes.map((subRoute, subKey) => (
          <Route
            path={subRoute.path}
            element={subRoute.component}
            key={`${key}-${subKey}`} 
          />
        ));
      }
      return (
        <Route path={route.path} element={route.component} key={key} />
      );
    });
  };

  return (
    <MainPanel>
      <Navbar />
      <Flex flexGrow={1} m={1.9}  >
        <Routes>
          {getRoutes(routes)}
        </Routes>
      </Flex>
      {/* <Footer /> */}
    </MainPanel>
  );
}
