import axios from "axios";
import { server_url } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const loginApi = createAsyncThunk("auth/login", async (data) => {
  try {
    console.log(data);
    
    const response = await axios.post(`${server_url}/admin/user-master/login`, data);    
    return response.data;
  } catch (error) {
    console.log("this is error", error.response?.data);
    throw error.response?.data;
  }          
});

export const forgetPassword = createAsyncThunk("auth/forgetPassword", async (data) => {
  try {
    console.log(data);
    
    const response = await axios.post(`${server_url}/user-master/forget-password`, data);
    console.log(response.data);
    
    return response.data;
  } catch (error) {
    console.log("this is error", error.response?.data);
    throw error.response?.data;
  }          
});
