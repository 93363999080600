import React, { useState } from "react";
import { Box, Input, Flex, VStack, useColorModeValue } from "@chakra-ui/react";

export default function AutoSearchCompleted({
  onSearch,
  onSelect,
  productListIndex,
  placeHolder,
  optionKey,
}) {
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState("");
  const [allOptions, setAllOptions] = useState([]);
  const [isFirstFocus, setIsFirstFocus] = useState(true);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setSelected("");

    if (onSearch) {
      onSearch(value, setAllOptions);
    }
  };

  const handleFocus = () => {
    if (isFirstFocus && onSearch) {
      onSearch("", setAllOptions);
      setIsFirstFocus(false);
    }
  };

  const selectHandle = (option) => {
    setSearchText("");
    setSelected(option[optionKey]);

    if (onSelect) {
      onSelect(option, productListIndex);
    }
  };

  return (
    <Flex direction="column" position="relative" width="100%" height="100%">
      <Input
        type="text"
        variant="primary" 
        value={searchText || selected}
        onChange={handleChange}
        onFocus={handleFocus}
        
      />

      <VStack
        align="stretch"
        display={searchText ? "flex" : "none"}
        maxHeight="175px"
        overflowY="auto"
        border="1px"
        borderColor="black"
        borderTopWidth="0"
        bg={useColorModeValue("white", "gray.700")}
        position="absolute"
        top="100%"
        zIndex="1"
        width="100%"
        borderRadius="0"
        spacing={0}
      >
        {allOptions?.length === 0 ? (
          <Box p={2} textAlign="center">
            Not Found
          </Box>
        ) : (
          allOptions?.map((option, i) => (
            <Box
              key={i}
              p={2}
              borderTopWidth={i === 0 ? "0" : "1px"}
              borderColor="gray.300"
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
              onClick={() => selectHandle(option)}
            >
              {option[optionKey]}
            </Box>
          ))
        )}
      </VStack>
    </Flex>
  );
}
