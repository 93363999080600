import {
  Box,
  Flex,
  Heading,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormLabel,
  Input,
  Stack,
  IconButton,
  CardFooter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { AddIcon } from "@chakra-ui/icons";
import CategoryCreate from "./CategoryCreate";
import BrandCreate from "./BrandCreate";
import AutoSearchCompleted from "../../../components/AutoSearchCompleted";
import { categorySearchApi } from "../services";
import PanelContainer from "../../../components/PanelContainer";

export default function ItemCreate() {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);

  const handleOpenCategoryModal = () => {
    setIsCategoryModalOpen(true);
  };

  const handleCloseCategoryModal = () => {
    setIsCategoryModalOpen(false);
  };

  const handleOpenBrandModal = () => {
    setIsBrandModalOpen(true);
  };

  const handleCloseBrandModal = () => {
    setIsBrandModalOpen(false);
  };

  const handleCategorySearch = async (value, setOptions) => {
    try {
      const result = await categorySearchApi(value);

      if (result.statusCode === 200 && result.data) {
        setOptions(result.data);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching category data:", error);
      setOptions([]);
    }
  };

  const handleCategorySelect = (data) => {
    // console.log(data);
  };
  return (
    <PanelContainer>
      <Flex justifyContent="space-between" gap={5}>
        <Box w="50%" display="flex" flexDirection="column" gap="14px">
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Category :
            </FormLabel>

            <AutoSearchCompleted
              onSearch={handleCategorySearch}
              optionKey="categoryName"
              onSelect={handleCategorySelect}
            />

            <IconButton
              aria-label="Add Category"
              icon={<AddIcon />}
              onClick={handleOpenCategoryModal} // Open Category Modal
              variant="outline"
              size="xs"
              sx={{
                borderRadius: 0,
                border: "1px solid green",
                padding: 0,
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Brand :
            </FormLabel>
            <AutoSearchCompleted />

            <IconButton
              aria-label="Add Brand"
              icon={<AddIcon />}
              onClick={handleOpenBrandModal} // Open Brand Modal
              variant="outline"
              size="xs"
              sx={{
                borderRadius: 0,
                border: "1px solid green",
                padding: 0,
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Item Code :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Product Name :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Print Name :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Price Rate :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Sale Price :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Min Sale Price :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              M.R.P :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Purchases Unit :
            </FormLabel>
            <AutoSearchCompleted />

            <IconButton
              aria-label="Add Brand"
              icon={<AddIcon />}
              onClick={() => console.log("Add button clicked")}
              variant="outline"
              size="xs"
              sx={{
                borderRadius: 0,
                border: "1px solid green",
                padding: 0,
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Sale Unit :
            </FormLabel>
            {/* <Input
                sx={{
                  borderRadius: "2px",
                  border: "1px solid gray",
                  padding: 0,
                  px: 0,
                  py: 0,
                  lineHeight: "1.5",
                  height: "auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  minWidth: "150px",
                }}
              /> */}
            <AutoSearchCompleted />

            <IconButton
              aria-label="Add Brand"
              icon={<AddIcon />}
              onClick={() => console.log("Add button clicked")}
              variant="outline"
              size="xs"
              sx={{
                borderRadius: 0,
                border: "1px solid green",
                padding: 0,
              }}
            />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Equivalent Number :
            </FormLabel>
            <Input type="text" variant="primary" />
            {/* <IconButton
                aria-label="Add Brand"
                icon={<AddIcon />}
                onClick={() => console.log("Add button clicked")}
                variant="outline"
                size="xs" 
                sx={{
                  borderRadius: 0,
                  border: "1px solid green",
                  padding: 0,
                }}
              /> */}
          </FormControl>
        </Box>
        <Box w="50%" display="flex" flexDirection="column" gap="14px">
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Opening Stock :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Opening Stock Value :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              HSN Code :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              CGST :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              SGST :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              IGST :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Sales Discount :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Low Level Limit :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Location :
            </FormLabel>

            <AutoSearchCompleted />

            <IconButton
              aria-label="Add Brand"
              icon={<AddIcon />}
              onClick={() => console.log("Add button clicked")}
              variant="outline"
              size="xs"
              sx={{
                borderRadius: 0,
                border: "1px solid green",
                padding: 0,
              }}
            />
          </FormControl>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              // background: "red",
            }}
          >
            <FormLabel whiteSpace="nowrap" color="black" pl={0} m={0}>
              Description :
            </FormLabel>
            <Input type="text" variant="primary" />
          </FormControl>
        </Box>
      </Flex>
      <CategoryCreate
        isModalOpen={isCategoryModalOpen}
        handleCloseModal={handleCloseCategoryModal}
      />
      <BrandCreate
        isModalOpen={isBrandModalOpen}
        handleCloseModal={handleCloseBrandModal}
      />
    </PanelContainer>
  );
}
