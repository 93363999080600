import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance"; 

export const getAllCategoryApi = createAsyncThunk("category/get-all", async (page) => {
    const token = localStorage.getItem('token');
    console.log("token",token);    
    try {
        const response = await axiosInstance.get(`/category/get-all?page=${page}`);
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
});

export const createCategoryApi = createAsyncThunk("category/create", async (data) => {
    try {
        const response = await axiosInstance.post(`/category/create`,data);
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
});


export const deleteCategoryApi = createAsyncThunk("category/delete", async (id) => {
    try {
        const response = await axiosInstance.delete(`/category/delete/${id}`);
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
});



// export const categoryGetApi = createAsyncThunk("category/get", async (id) => {
//     try {
//         const response = await axiosInstance.get(`/category/get/${id}`);
//         return response.data;
//     } catch (error) {
//         console.log("this is error", error.response?.data);
//         throw error.response?.data;
//     }
// });

export const categoryGetApi = async (id) => {
    try {
        const response = await axiosInstance.get(`/category/get/${id}`);
        return response.data;
    } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
    }
}


export const updateCategoryApi = createAsyncThunk("category/update",async ({ data, id }) => {
      try {
        const response = await axiosInstance.put(`/category/update/${id}`, data);
        return response.data;
      } catch (error) {
        console.log("this is error", error.response?.data);
        throw error.response?.data;
      }
    }
  );

