import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Card,
  CardHeader,
  CardBody,
  Text,
} from "@chakra-ui/react";

export default function Model({ isOpen, onClose, title, children, footerButton }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={0} >
        <ModalHeader p={0} >
          <Card borderRadius={0} display="flex" justifyContent='space-between' >
            <CardHeader m={0} p={2} >
              <Text fontSize="md">{title}</Text>
            </CardHeader>
            <ModalCloseButton />
          </Card>
        </ModalHeader>
        <CardBody p={2}>
          {children}
        </CardBody>
        <ModalFooter p={1}>
          {footerButton}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
