import {
  Flex,
  useColorMode,
  useColorModeValue,
  Text,
  Box,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Button,
  Center,
  Heading,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HSeparator } from "../../../components/Separator";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { loginApi } from "../../../modules/Auth/services";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";


export default function Login() {
  const { colorMode } = useColorMode();
  const bgForm = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isLoading, isSuccess, isError, message,token } = useSelector(
    (state) => state.login
  );



  const onSubmit = (data) => {
    console.log(data);
    dispatch(loginApi(data));
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Login",
        description: message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
  

      if (localStorage.getItem('token') != null) {
        navigate("/");

      }
     }

    if (isError) {
      toast({
        title: "Login failed.",
        description: message || "Invalid credentials, please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [isSuccess, isError, message, navigate, toast]);


  return (
    <Flex
      w="100%"
      h="100vh"
      bg={colorMode === "light" ? "#3182ce" : "navy.900"}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        direction="column"
        w="445px"
        background="transparent"
        p="40px"
        bg={bgForm}
        boxShadow={useColorModeValue(
          "0px 5px 14px rgba(0, 0, 0, 0.05)",
          "unset"
        )}
      >
        <Box>
          <Heading
            size="lg"
            fontWeight="bold"
            textAlign="center"
            color={textColor}
          >
            Login
          </Heading>
          <HSeparator my="15px" />
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Customer Id
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              mb="24px"
              size="lg"
              name="email"
              border="1px solid"
              {...register("customerId", {
                required: "CustomerId is required",
              })}
            //   isInvalid={!!errors.email}
            />
            {errors.customerId && (
              <Text color="red.500">{errors.customerId.message}</Text>
            )}

            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              mb="24px"
              size="lg"
              name="email"
              border="1px solid"
              {...register("email", { required: "Email is required" })}
            //   isInvalid={!!errors.email}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}

            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              mb="24px"
              size="lg"
              name="password"
              border="1px solid"
              {...register("password", { required: "Password is required" })}
              isInvalid={!!errors.password}
            />
            {errors.password && (
              <Text color="red.500">{errors.password.message}</Text>
            )}

            <FormControl display="flex" alignItems="center" mb="24px">
              <Switch
                id="remember-login"
                colorScheme="blue"
                me="10px"
                name="rememberMe"
              />
              <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
                Remember me
              </FormLabel>
            </FormControl>

            {/* <Center>  

              {isLoading && <Spinner mb={5}/>
            }
            </Center> */}

            <Button
              fontSize="18px"
              variant="primary"
              fontWeight="bold"
              w="100%"
              h="45"
              type="submit"
            >
              Log In
            </Button>
          </FormControl>
        </form>
      </Flex>
    </Flex>
  );
}
