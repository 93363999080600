import { createSlice } from "@reduxjs/toolkit";
import { loginApi } from "../services";

const initialState = {
  data: null,
  token: localStorage.getItem('token') || null,  
  isLoading: false,
  isSuccess: !!localStorage.getItem('token'),
  isError: false,
  message: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout(state,action) {
      state.data = null;
      state.token = null;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;

      localStorage.removeItem('token');

      if (action.payload && action.payload.redirect) {
        window.location.href = '/login'; // Full page reload for Axios interceptor
      }

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginApi.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(loginApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.data = action.payload;
        state.token = action.payload.accessToken;     
        localStorage.setItem('token', action.payload.data.accessToken);

      })
      .addCase(loginApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message;
      });
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
