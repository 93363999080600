import React from 'react'
import { Routes, Route, Navigate, } from 'react-router-dom'
import Layout from './components/layouts'
import Auth from './modules/Auth'
import ProtectedRoute from './components/ProtectedRoute'


export default function App() {
  
  return (

    <Routes>
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      />
      <Route path='/login' element={<Auth />} />
      {/* <Route path="/" element={<Navigate to="/login" replace/>}/> */}

    </Routes>

  )
}
